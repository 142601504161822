/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-05-16 11:31:18
 * @LastEditors: zsz 210891682@qq.com
 * @LastEditTime: 2024-05-20 10:55:14
 * @FilePath: \app-home\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [
  {
    path: "/xq/:id?",
    name: "xq",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/star"),
    meta: {
      title: "米莉星球",
    },
  },
  {
    path: "/ts/:id?",
    name: "ts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/explore"),
    meta: {
      title: "星球",
    },
  },
  {
    path: "/jy/:id?",
    name: "jy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/friends"),
    meta: {
      title: "米莉交友",
    },
  },
  {
    path: "/yy/:id?",
    name: "yy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/speech"),
    meta: {
      title: "怪兽语音",
    },
  },
  {
    path: "/pd/:id?",
    name: "pd",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/party"),
    meta: {
      title: "心怡派对",
    },
  },
  {
    path: "/cp/:id?",
    name: "cp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/cp"),
    meta: {
      title: "米莉星球cp",
    },
  },
  {
    path: "/pulu/xq/:id?",
    name: "puluXq",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/puluStar"),
    meta: {
      title: "卟噜星球",
    },
  },
  {
    path: "/pulu/pd/:id?",
    name: "puluPd",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/puluParty"),
    meta: {
      title: "卟噜派对",
    },
  },
  {
    path: "/pulu/kj/:id?",
    name: "puluCommun",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/puluCommunity"),
    meta: {
      title: "卟噜空间",
    },
  },
  {
    path: "/pulu/jy/:id?",
    name: "puluFriends",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/puluFriends"),
    meta: {
      title: "卟噜交友",
    },
  },
  {
    path: "/wj/:id?",
    name: "wj",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wj"),
    meta: {
      title: "米莉玩家",
    },
  },
  {
    path: "/jh/:id?",
    name: "jh",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/jh"),
    meta: {
      title: "米莉聚会",
    },
  },
  {
    path: "/zy/:id?",
    name: "zy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/zy"),
    meta: {
      title: "米莉之歌",
    },
  },
  {
    path: "/zg/:id?",
    name: "zg",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/zg"),
    meta: {
      title: "米莉之歌",
    },
  }
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { path, meta } = to;
  meta.title && (document.title = meta.title)
  next()
})

export default router;